
<template>
    <div class="flex flex-col items-center">
        <img alt="TheTwinSouls Logo" src="tts_logo_nobg.png"
            class="w-[80vw] mx-auto min-w-[80vw] max-w-[80vw] md:w-[40vw] md:min-w-[40vw] md:max-w-[40vw] 2xl:w-[30vw] 2xl:min-w-[30vw] 2xl:max-w-[30vw]" />
        <div class="flex flex-row justify-center align-middle items-middle">
            <div class="color-primary flex h-[35px] align-middle justify-end mr-2">
                <div class="text-lg align-middle mt-1">
                    PASSWORD
                </div>
            </div>
            <VaInput v-model="interface_password" type="password" class="mb-6 color-primary" />
        </div>

        <div>
            <img src="../assets/login.png" class="cursor-pointer animate-hover" @click="try_login()">
        </div>
    </div>
</template>
<script setup lang="ts">
import { Ref, inject, ref } from "vue";
import { VaInput, VaButton, VaIcon, useToast } from 'vuestic-ui';

const interface_password: Ref<string> = ref("");

const toast = useToast();
const emitter: any = inject('emitter');

const try_login = () => {
    fetch("https://api.kashnars.eu/login", {
        method: 'POST',
        body: JSON.stringify({
            pass: interface_password.value
        }),
        headers: {
            'Content-Type': 'application/json'
        },
    }).then((result) => {
        return result.json(); // Extract JSON data
    }).then(data => {
        // Use the extracted JSON data here
        if (data.status === "authentication_failure") {
            toast.init({ message: 'Bad credentials.', color: 'warning' });
        }
        if (data.status === "bruteforce") {
            toast.init({ message: 'Stop spam.', color: 'danger' });
        }
        if (data.status === "authentication_success") {
            localStorage.setItem('validPass', interface_password.value);
            emitter.emit('setLogged', true);
            toast.init({ message: 'Welcome.', color: 'success' });
        }
    });
}

</script>
<style>
@keyframes scaleUp {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}

@keyframes scaleDown {
    0% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.animate-hover:hover {
    animation: scaleUp 0.3s ease-out forwards;
}

.animate-hover:not(:hover) {
    animation: scaleDown 0.3s ease-out forwards;
}

.color-primary .va-input__content__input {
    color: var(--va-primary);
}

.color-primary {
    color: var(--va-primary);
}

.color-matrix {
    color: var(--va-matrix);
}
</style>