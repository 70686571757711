<template>
    <div class="flex flex-col">
        <div class="flex flex-row mx-auto flex-wrap">
            <div class="w-[100vw] mx-auto min-w-[100vw] max-w-[20vw] md:w-[20vw] md:min-w-[20vw] md:max-w-[20vw]">
                <img alt="TheTwinSouls Logo" src="tts_logo_nobg.png" class="w-[100%] h-auto" />
            </div>
            <div
                class="flex flex-col mx-auto w-[80vw] min-w-[80vw] max-w-[80vw] md:w-[20vw] md:min-w-[20vw] md:max-w-[20vw]">
                <VaTextarea v-model="commands" class="color-matrix border-matrix select-text min-h-[180px]"
                    spellcheck="false" @input="formatCommands()" />
                <VaButton color="primary" class="mt-10 h-[36px] max-h-[36px]" @click="sendCommands()">SEND</VaButton>
            </div>
        </div>
        <div class="mt-20">
            <p class="text-start text-lg color-matrix">
                Casual: game_type 0; game_mode 0;<br />
                Competitive: game_type 0; game_mode 1;<br />
                Scrim Competitive 2v2: game_type 0; game_mode 2;<br />
                Scrim Competitive 5v5: game_type 0; game_mode 2;<br />
                Arms Race / Gun Game: game_type 1; game_mode 0;<br />
                Demolition: game_type 1; game_mode 1;<br />
                Deathmatch: game_type 1; game_mode 2;<br />
                Training: game_type 2; game_mode 0;<br />
                Custom: game_type 3; game_mode 0;<br />
                Cooperative: game_type 4; game_mode 0;<br />
                Skirmish: game_type 5; game_mode 0;<br />
                <br />
                Change map: map de_inferno;<br />
                Workshop maps: host_workshop_map 3070303394;<br />
                <br />
                Loads of money: mp_maxmoney 100000;mp_startmoney 100000;mp_afterroundmoney 100000;<br />
                Restart game: mp_restartgame 1;<br />
                Change max rounds: mp_maxrounds 24;<br />
                Team randoms: mp_scrambleteams;<br />
            </p>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Ref, ref } from 'vue';
import { inject } from 'vue';
import { useToast } from 'vuestic-ui/web-components';

const toast = useToast();

const emitter: any = inject('emitter');
const commands: Ref<string> = ref("");

const formatCommands = () => {
    const commandsArray = commands.value.split(/\n|;/ig);

    commands.value = commandsArray.join('\n');
};

const sendCommands = () => {
    const commandsArray = commands.value.split(/\n|;/ig);

    console.log(commandsArray);
    if (commandsArray && commandsArray.length > 0) {
        fetch("https://api.kashnars.eu/command", {
            method: 'POST',
            body: JSON.stringify({
                pass: localStorage.getItem('validPass'),
                command: commandsArray
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((result) => {
            return result.json(); // Extract JSON data
        }).then(data => {
            // Use the extracted JSON data here
            if (data.status === "authentication_failure") {
                toast.init({ message: 'Bad credentials.', color: 'warning' });
            }
            if (data.status === "bruteforce") {
                toast.init({ message: 'Stop spam.', color: 'danger' });
            }
            if (data.status === "authentication_success") {
                if (data.error) {
                    toast.init({ message: data.error, color: 'danger' });
                }

                if (data.success) {
                    toast.init({ message: data.success, color: 'success' });
                    commands.value = '';
                }
            }
        });
    }
};

</script>
<style>
.color-matrix {
    color: var(--va-matrix);
}

.border-matrix .va-input-wrapper__field {
    border: 1px solid var(--va-matrix);
    border-radius: 0;
}
</style>