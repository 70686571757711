<template>
    <div class="flex flex-col w-full justify-center max-w-[2000px] mx-auto">
        <AppMain></AppMain>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AppMain from "./components/AppMain.vue";
import LoginForm from "./components/LoginForm.vue";
import LoggedApp from "./components/LoggedApp.vue";
import 'vuestic-ui/dist/vuestic-ui.css';

@Options({
    components: {
        AppMain,
        LoginForm,
        LoggedApp
    },
})
export default class App extends Vue { }
</script>

<style>
body {
    background-color: #111111;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
