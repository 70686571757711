<template>
    <div class="flex flex-col mx-auto">
        <LoginForm v-if="!isLogged"></LoginForm>
        <LoggedApp v-else></LoggedApp>
    </div>
</template>
<script setup lang="ts">
import { Ref, ref } from 'vue';
import LoginForm from './LoginForm.vue';
import { inject } from 'vue';
import LoggedApp from './LoggedApp.vue';

const emitter: any = inject('emitter');

const isLogged: Ref<boolean> = ref(false);

emitter.on('setLogged', (value: boolean) => {   // *Listen* for event
    isLogged.value = value;
});

</script>