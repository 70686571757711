import { createApp } from 'vue';
import App from './App.vue';
import './tailwind.css';
import { createVuestic } from "vuestic-ui";
import "vuestic-ui/css";
import mitt from 'mitt';

const emitter = mitt();
const app = createApp(App);

app.provide('emitter', emitter);

app.use(
    createVuestic({
        config: {
            colors: {
                variables: {
                    // Default colors
                    primary: "#DA6930",
                    secondary: "#0f0f0f",
                    success: "#40e583",
                    info: "#2c82e0",
                    danger: "#e34b4a",
                    warning: "#ffc200",
                    gray: "#babfc2",
                    dark: "#34495e",
                    backgroundPrimary: "#0f0f0f",
                    matrix: "#A7D9AA"
                },
            },
        },
    })
);

app.mount('#app');